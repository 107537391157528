// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","container":"container_bca2bc4","hasMarginTop":"hasMarginTop_fc2a1a8","label":"label_c94e565","outerCheckbox":"outerCheckbox_f774878","innerCheckbox":"innerCheckbox_c4cfdbc","check":"check_d275308","indeterminate":"indeterminate_a90eedc","text":"text_d6092e2","button":"button_ee36a53","buttonContainer":"buttonContainer_fe5184e","blue":"blue_c10c430","red":"red_d88622c","disabled":"disabled_d5a4e82","disabledInnerCheckbox":"disabledInnerCheckbox_c8a883f","disabledLabel":"disabledLabel_cc970a9","defaultCursor":"defaultCursor_d6e0d64","block":"block_ed73282"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"container\":\"container_bca2bc4\",\"hasMarginTop\":\"hasMarginTop_fc2a1a8\",\"label\":\"label_c94e565\",\"outerCheckbox\":\"outerCheckbox_f774878\",\"innerCheckbox\":\"innerCheckbox_c4cfdbc\",\"check\":\"check_d275308\",\"indeterminate\":\"indeterminate_a90eedc\",\"text\":\"text_d6092e2\",\"button\":\"button_ee36a53\",\"buttonContainer\":\"buttonContainer_fe5184e\",\"blue\":\"blue_c10c430\",\"red\":\"red_d88622c\",\"disabled\":\"disabled_d5a4e82\",\"disabledInnerCheckbox\":\"disabledInnerCheckbox_c8a883f\",\"disabledLabel\":\"disabledLabel_cc970a9\",\"defaultCursor\":\"defaultCursor_d6e0d64\",\"block\":\"block_ed73282\"}";
        // 1737753172373
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  