// extracted by mini-css-extract-plugin
export default {"digits":"digits_da48a74","digit":"digit_a2749d4","char":"char_f502af7","emptyChar":"emptyChar_fc7936f"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"digits\":\"digits_da48a74\",\"digit\":\"digit_a2749d4\",\"char\":\"char_f502af7\",\"emptyChar\":\"emptyChar_fc7936f\"}";
        // 1737753173955
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  