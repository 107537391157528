// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","dialogHeader":"dialogHeader_db87b41","closeButton":"closeButton_f2afb99","headerTitle":"headerTitle_ce95288","dialogBody":"dialogBody_d740729","dialogFooter":"dialogFooter_e5b6735","formErrors":"formErrors_f2b5a26","formErrorsLink":"formErrorsLink_ce35d54","formErrorsSmallDialog":"formErrorsSmallDialog_b4ce44c","section":"section_b4c1c19","sectionUnified":"sectionUnified_a3841af","sectionUnifiedWithoutNegativeMargin":"sectionUnifiedWithoutNegativeMargin_ad2bf73","sectionMultiLine":"sectionMultiLine_f1c5aa7","sectionChildren":"sectionChildren_e2be94a","sectionChildrenColumn":"sectionChildrenColumn_ba480e5","sectionNumber":"sectionNumber_cdbdabd"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"dialogHeader\":\"dialogHeader_db87b41\",\"closeButton\":\"closeButton_f2afb99\",\"headerTitle\":\"headerTitle_ce95288\",\"dialogBody\":\"dialogBody_d740729\",\"dialogFooter\":\"dialogFooter_e5b6735\",\"formErrors\":\"formErrors_f2b5a26\",\"formErrorsLink\":\"formErrorsLink_ce35d54\",\"formErrorsSmallDialog\":\"formErrorsSmallDialog_b4ce44c\",\"section\":\"section_b4c1c19\",\"sectionUnified\":\"sectionUnified_a3841af\",\"sectionUnifiedWithoutNegativeMargin\":\"sectionUnifiedWithoutNegativeMargin_ad2bf73\",\"sectionMultiLine\":\"sectionMultiLine_f1c5aa7\",\"sectionChildren\":\"sectionChildren_e2be94a\",\"sectionChildrenColumn\":\"sectionChildrenColumn_ba480e5\",\"sectionNumber\":\"sectionNumber_cdbdabd\"}";
        // 1737753162526
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  